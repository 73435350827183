import.meta.env = {"BASE_URL": "/", "DEV": true, "MODE": "development", "PROD": false, "SSR": false, "VITE_API_URL": "http://localhost:8000", "VITE_PORT": "3000"};import "/src/assets/main.css"

import { createApp } from "/node_modules/.vite/deps/vue.js?v=8e302fe6"
import { createPinia } from "/node_modules/.vite/deps/pinia.js?v=97addb62"

import App from "/src/App.vue"
import router from "/src/router/index.js"

import "/node_modules/vuetify/lib/styles/main.css"
import { createVuetify } from "/node_modules/.vite/deps/vuetify.js?v=07c53bd2"
import * as components from "/node_modules/.vite/deps/vuetify_components.js?v=cf2de041"
import * as directives from "/node_modules/.vite/deps/vuetify_directives.js?v=8ed2b8c5"
import "/node_modules/@mdi/font/css/materialdesignicons.css"

import ElementPlus from "/node_modules/.vite/deps/element-plus.js?v=6c75c7cf"
import "/node_modules/element-plus/dist/index.css"

import Toast from "/node_modules/.vite/deps/vue-toastification.js?v=ba7ad074"
import "/node_modules/vue-toastification/dist/index.css"

import axios from "/node_modules/.vite/deps/axios.js?v=1bc75c1f"

axios.defaults.baseURL = import.meta.env.VITE_API_URL || 'http://localhost:8000'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light'
  }
})

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(vuetify)
app.use(ElementPlus)
app.use(Toast, {
  position: 'top-right',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
})

app.mount('#app')
